module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"iCash website","short_name":"iCash","description":"With iCash it's easy to apply and instant approval! Money by e-transfer all over Canada.","lang":"en","start_url":"/","background_color":"#2E61EF","theme_color":"#FFFFFF","display":"standalone","icon":"src/images/icash-symbol-black-sized.svg","icons":[{"src":"src/images/icons/icash-symbol-x196.png","sizes":"196x196","type":"image/png","purpose":"any"},{"src":"src/images/icons/maskable_icon_x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"src/images/icons/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"91795f5182e6c6ddb8c91adc3734cea4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
