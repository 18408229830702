import "typeface-poppins"
import "@brainfinance/icash-component-library/dist/index.css"
import "./src/styles/global.css"

const jquery = require("jquery")

global.$ = jquery;
global.jQuery = jquery;

/**
 * Implement Gatsby's Browser APIs in this file.
 
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Displaying a message when a service worker updates
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}